let fabricPrintingMachineTypes = [
  'ROTARY_PRINTING_MACHINE',
  'FLATBED_PRINTING_MACHINE',
  'DIGITAL_PRINTING_MACHINE',
  'SUBLIMATION_MACHINE',
  'OTHER'

]

let accessFactoryMachineTypes = [
  'OPEN_CATEGORY'
]
let deminLaundryMachineTypes = [
  'BELLY_WASHER_FRONT_LOAD',
  'TOP_LOADING_MACHINE',
  'TUMBLE_DRYER',
  'OZONE_MACHINE',
  'LASER_MACHINE',
  'SPRAYING_BOOTH_WITH_WATER',
  'SPRAYING_BOOTH_WITHOUT_WATER',
  'HYDRO_EXTRACTOR',
  'OTHER'


]

let facotryDyingMillMachineTypes = [
  'JET_DYEING_MACHINE',
  'JIGGER_DYEING_MACHINE',
  'BEAM_DYEING_MACHINE',
  'WINCH_DYEING_MACHINE',
  'PAD_PADDING_DYEING_MACHINE',
  "WASHINGRANGE",
  "PADDINGDYEINGMACHINE",
  "COMPACTINGMACHINE",
  "STENTER",
  "SANFORISINGMACHINE",
  "CALENDERISINGMACHINE",
  'OTHER'
]

let facotryYarnDyingMillMachineTypes = [
  "PACKAGE_DYEING_MACHINE", "HORIZONTAL_DYEING_MACHINE", "DRYER", "OTHER"
]
let testMachineTypes = [
  'TEST_MACHINE_1',
  'TEST_MACHINE_2'
]



export class FactoryTypeData {
  id: number | null;
  masterFactoryType: number ;
  factoryTypeId: number | null;
  factoryTypeName: string | null;
  fabricTypes: FabricType[];
  garmentTypes: GarmentType[];
  garmentProductionPerYear: number | null;
  categoryTypes?: GarmentType[];
  processedMaterials: ProcessedMaterial[];
  denim: Denim | null;
  nonDenim: NonDenim | null;
  processes: Process[];
  machineTypes: MachineType[];
  machineTypesDataSource?: any;
  processesGroup?: { key?: string, processes: Process[] }[]
  applicableForPo?: boolean;
  productionInputTypes?: ProductionInputType[];
  measuringUnits?: String

}


// Factory Type Class Declare

export class FactoryType {
  id: number;
  isChecked: boolean;
  name: string;
  machineTypes: any[];
  processesIds: number[];
  productionIds: number[];

  constructor(id: number, isChecked: boolean, name: string, machineTypes: any[], processesIds?: number[], productionIds?: number[]) {
    this.id = id;
    this.isChecked = isChecked;
    this.name = name;
    this.machineTypes = machineTypes;
    this.processesIds = processesIds;
    this.productionIds = productionIds;
  }
}

// Fabric Type Class Declare
export class FabricType {
  id: number;
  factoryType: number[];
  isChecked: boolean;
  name: string;
  productionQuantity: number;
  unit: string = 'Pieces/Year';

  constructor(id: number, factoryType: number[], isChecked: boolean, name: string, productionQuantity: number) {
    this.id = id;
    this.factoryType = factoryType;
    this.isChecked = isChecked;
    this.name = name;
    this.productionQuantity = productionQuantity
  }
}


export class ProductionInputType {
  id: number;
  factoryType: number[];
  isChecked: boolean;
  name: string;
  productionQuantity: number;
  unit: string = 'Pieces/Year';

  constructor(id: number, factoryType: number[], isChecked: boolean, name: string, productionQuantity: number) {
    this.id = id;
    this.factoryType = factoryType;
    this.isChecked = isChecked;
    this.name = name;
    this.productionQuantity = productionQuantity
  }
}

export class GarmentType {
  id: number;
  factoryType: number[];
  isChecked: boolean;
  name: string;
  unit: number | null;
  productionQuantity: number;
  constructor(id: number, factoryType: number[], isChecked: boolean, name: string, productionQuantity: number) {
    this.id = id;
    this.factoryType = factoryType;
    this.isChecked = isChecked;
    this.name = name;
    this.productionQuantity = productionQuantity
  }
}

export class ProcessedMaterial {
  id: number;
  factoryType: number[];
  isChecked: boolean;
  materialValue: number | null;
  unit: number | null;
  name: string;

  constructor(
    id: number,
    factoryType: number[],
    isChecked: boolean,
    materialValue: number | null,
    unit: number | null,
    name: string
  ) {
    this.id = id;
    this.factoryType = factoryType;
    this.isChecked = isChecked;
    this.materialValue = materialValue;
    this.unit = unit;
    this.name = name;
  }
}

export class Denim {
  id: number;
  factoryType: number[];
  isChecked: boolean;
  value: number | null;
  name: string = 'Denim';

  constructor(
    id: number,
    factoryType,
    isChecked: boolean,
    value: number | null,
    // name: string
  ) {
    this.id = id;
    this.factoryType = factoryType
    this.isChecked = isChecked;
    this.value = value;
    // this.name = name;
  }
}

export class NonDenim {
  id: number;
  factoryType: number[];
  isChecked: boolean;
  value: number | null;
  name: string = 'Non Denim';

  constructor(
    id: number,
    factoryType: number[],
    isChecked: boolean,
    value: number | null,
    // name: string
  ) {
    this.id = id;
    this.factoryType = factoryType;
    this.isChecked = isChecked;
    this.value = value;
    // this.name = name;
  }
}

export class Process {
  id: number;
  factoryType: number[];
  isChecked: boolean;
  name: string;
  category: string;
  subProcesses: Process[];
  inputAllowed: boolean
  unit: number | null;
  productionQuantity: number | null;



  constructor(id: number, factoryType: number[], isChecked: boolean, name: string, category: string, subProcesses?: Process[], inputAllowed?: boolean, productionQuantity?: number, unit?: number) {
    this.id = id;
    this.factoryType = factoryType;
    this.isChecked = isChecked;
    this.name = name;
    this.category = category;
    if (subProcesses) {
      this.subProcesses = subProcesses
    }
    this.inputAllowed = inputAllowed
    if (productionQuantity) {
      this.productionQuantity = productionQuantity
      this.unit = unit
    }
  }
}

class MachineType {
  id: number;
  name: string;
  model: string;
  machineAmount: number;
  manufacturer: string;
  manufacturingYear?: number;
  lr?: string;
  averageWaterConsumption?: number;
  unit?: string;
  maximumCapacity?: number;
  usedForProcess?: string;
  documentName?: string;
  documentIssuedDate?: string;
  acceptTermAndCondition?: boolean;
  freshWaterSource: string | null;
  recycledWaterSource: string | null;
  reUsedWaterSource: string | null;
}


export const piecesInputFactoryType = [1, 2, 6, 8, 15, 17]

export const factoryTypes: FactoryType[] = [
  new FactoryType(1, false, 'Accessory Factory', [], [22, 23, 24, 25, 26]),//, accessFactoryMachineTypes),
  new FactoryType(2, false, 'Denim Laundry', deminLaundryMachineTypes, [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 31]),
  new FactoryType(3, false, 'Denim Fabric Mill', [], [42, 47]),
  new FactoryType(4, false, 'Fabric Dyeing Mill', facotryDyingMillMachineTypes, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 38, 39, 40]),
  new FactoryType(5, false, 'Fabric Printing Mill', fabricPrintingMachineTypes, [27, 28, 29, 30, 32]),
  new FactoryType(6, false, 'Piece Printing', ["TABLE_PRINTER", "BUTTERFLY_PRINTER", "OTHER"], [], [1]),
  // new FactoryType(7, false, 'Shoe Assembly', testMachineTypes),
  new FactoryType(8, false, 'Washing Mill', deminLaundryMachineTypes, [33, 34, 35, 36, 37, 32]),
  new FactoryType(9, false, 'Yarn Dyeing Mill', facotryYarnDyingMillMachineTypes, [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 38, 39, 40]),
  new FactoryType(10, false, 'Knitting Mill', ['CIRCULAR_KNITTING_MACHINE', 'FLAT_KNITTING_MACHINE', 'SEAMLESS_KNITTING_MACHINE'], [102, 103, 104, 105]),
  new FactoryType(11, false, 'Spinning Mill', ['RING_SPINNIG_MACHINE', 'ROTOR_SPINNING_MACHINE', 'AIR_JET_SPINNING_MACHINE'], [106, 107, 108, 109, 110, 111]),
  new FactoryType(12, false, 'Weaving Mill', ["WINDING_MACHINE", "SIZING_MACHINE", "AIR_JET_LOOM", "WATER_JET_LOOM", "RAPIER_LOOM", "PROJECTILE_LOOM", "MULTIPHASE_LOOM", "HAND_WEAVING_LOOM", "OTHER"], [112, 113, 114]),
  // new FactoryType(13, false, 'q ', ["OTHER"], []),
  // new FactoryType(14, false, 'qq ', ["OTHER"], []),
  new FactoryType(15, false, 'Label Producer', [], [], [2, 3]),
  //new FactoryType(16, false, 'Other', testMachineTypes),
  new FactoryType(17, false, 'CMT Unit', ["CUTTING_MACHINE", "MANUAL_CUTTING_DEVICE", "SEWING_MACHINE", "OVERLOCK_MACHINE", "SEWING_AUTOMATE", "IRONING_STATION", "PRESSING_MACHINE", "PUNCH_CUTTING_PRESS", "TOPPER", "FIXING_TUNNEL", "OTHER"], [115, 116, 117])

];

const maxId = Math.max(...factoryTypes.map(factoryType => factoryType.id));
export const factoryTypeNamesArray = Array(maxId).fill(null);

factoryTypes.forEach(factoryType => {
  factoryTypeNamesArray[factoryType.id - 1] = factoryType.name;
});



export const fabricTypes: FabricType[] = [
  new FabricType(1, [4, 5], false, 'Woven', null),
  new FabricType(2, [4, 5], false, 'Knits', null),
  new FabricType(3, [4, 5], false, 'Non-Woven', null),
  new FabricType(4, [4, 5], false, 'Other', null)
];

export const garmentTypes: GarmentType[] = [
  //if added or removed factory type the please update same in html of production module

  // Denim Laundry
  new GarmentType(1, [2, 8, 17], false, 'Bottoms/Trousers', null),
  new GarmentType(2, [2, 8, 17], false, 'Top/Shirts', null),
  new GarmentType(3, [2, 8, 17], false, 'Jacket', null),
  new GarmentType(4, [2, 8, 17], false, 'Skirt', null),

  // Accessory Factory
  new GarmentType(6, [1], false, 'Bags', null),
  new GarmentType(7, [1], false, 'Belts', null),
  new GarmentType(8, [1], false, 'Hats/Caps', null),
  new GarmentType(9, [1], false, 'Gloves', null),
  new GarmentType(10, [1], false, 'Scarfs', null),
  new GarmentType(11, [1], false, 'Other', null),


  new GarmentType(12, [17], false, 'Socks', null),
  new GarmentType(13, [17], false, 'Underwear', null),
  new GarmentType(14, [17], false, 'Nightwear', null),
  new GarmentType(15, [17], false, 'Outerwear', null),
  new GarmentType(16, [17], false, 'Woven shirt', null),
  new GarmentType(17, [17], false, 'Jersey', null),
  new GarmentType(18, [17], false, 'Stocking', null),
  new GarmentType(19, [17], false, 'Baby romper', null),
  new GarmentType(20, [17], false, 'Sweater', null),

  new GarmentType(5, [2, 8, 17], false, 'Other', null),



];



export const productionInputTypes: FabricType[] = [
  new ProductionInputType(1, [6], false, null, null),
  new ProductionInputType(2, [15], false, 'Woven Labels', null),
  new ProductionInputType(3, [15], false, 'Printed Labels', null)
];


export const categoryTypes: FabricType[] = [
  new FabricType(1, [6, 7, 8, 16], false, 'Test Item 1', null),
  new FabricType(2, [6, 7, 8, 16], false, 'Test Item 2', null),
  new FabricType(3, [6, 7, 8, 16], false, 'Test Item 3', null),
  new FabricType(4, [6, 7, 8, 16], false, 'Other', null)
];

export const denim = [2]; // Factory Type
export const nonDenim = [2];



export let processedMaterials: ProcessedMaterial[] = [
  // new ProcessedMaterial(1, [4, 5, 6, 9], false, null, null, 'Cotton'),
  // new ProcessedMaterial(2, [4, 5, 6, 9], false, null, null, 'Cotton/PES'),
  // new ProcessedMaterial(3, [4, 5, 6, 9], false, null, null, 'Viscose (or other CV materials)'),
  // new ProcessedMaterial(4, [4, 5, 6, 9], false, null, null, 'Acrylic'),
  // new ProcessedMaterial(5, [4, 5, 6, 9], false, null, null, 'PA (Nylon)'),
  // new ProcessedMaterial(6, [4, 5, 6, 9], false, null, null, 'PES (Polyester)'),
  // new ProcessedMaterial(7, [4, 5, 6, 9], false, null, null, 'Silk'),
  // new ProcessedMaterial(8, [4, 5, 6, 9], false, null, null, 'Wool (or other animal fiber)'),
  // new ProcessedMaterial(9, [4, 5, 6, 9], false, null, null, 'Other'),


  new ProcessedMaterial(1, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Cotton'),
  new ProcessedMaterial(2, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Cotton/PES'),
  new ProcessedMaterial(3, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Viscose (or other CV materials)'),
  new ProcessedMaterial(4, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Acrylic'),
  new ProcessedMaterial(5, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'PA (Nylon)'),
  new ProcessedMaterial(6, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'PES (Polyester)'),
  new ProcessedMaterial(7, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Silk'),
  new ProcessedMaterial(8, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Wool (or other animal fiber)'),
  new ProcessedMaterial(10, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Polyamide'),
  new ProcessedMaterial(11, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Tencel'),
  new ProcessedMaterial(12, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Hemp'),
  new ProcessedMaterial(13, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Nylon'),
  new ProcessedMaterial(14, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'OnceMore'),
  new ProcessedMaterial(15, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Organic Cotton'),
  new ProcessedMaterial(16, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Elastane'),
  new ProcessedMaterial(17, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Jersey'),
  new ProcessedMaterial(18, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'non-PVC'),
  //for sequence on UI other field added at end
  new ProcessedMaterial(9, [4, 5, 6, 9, 10, 11, 12, 13, 14], false, null, null, 'Other'),
];





//export let processes: Process[] = [
// *** Fabric Dyeing Mill start *** ///
// new Process(1, [4], false, 'Bleaching', 'PRE_TREATMENT'),
// new Process(2, [4], false, 'Scouring', 'PRE_TREATMENT'),
// new Process(3, [4], false, 'Desizing', 'PRE_TREATMENT'),
// new Process(4, [4], false, 'Mercerizing', 'PRE_TREATMENT'),
// new Process(5, [4], false, 'Exhaust Dyeing', 'DYEING'),
// new Process(6, [4], false, 'Cold Pad Batch', 'DYEING'),
// new Process(7, [4], false, 'Thermosol Dyeing', 'DYEING'),
// new Process(8, [4], false, 'Coating', 'FINISHING'),
// new Process(9, [4], false, 'Chemical Finishing', 'FINISHING'),
// new Process(10, [4], false, 'Mechanical Finishing', 'FINISHING'),
// *** Fabric Dyeing Mill end *** ///

// *** Denim Laundry start  *** //
// new Process(11, [2], false, 'Desizing', ''),
// new Process(12, [2], false, 'Stone Washing', ''),
// new Process(13, [2], false, 'Acid Washing', ''),
// new Process(14, [2], false, 'Rinse Washing', ''),
// new Process(15, [2], false, 'Bleaching', ''),
// new Process(16, [2], false, 'Garment Dyeing/Tinting', ''),
// new Process(17, [2], false, 'Over-Dyeing', ''),
// new Process(18, [2], false, 'Enzyme Washing', ''),
// new Process(19, [2], false, 'Ozone Treatment', ''),
// new Process(20, [2], false, 'PP-Spraying', ''),
// new Process(21, [2], false, 'Laser', ''),
// *** Denim Laundry end  *** //


// *** Accessory Factory start  *** //
// new Process(22, [1], false, 'Glueing', ''),
// new Process(23, [1], false, 'Painting', ''),
// new Process(24, [1], false, 'Spraying', ''),
// new Process(25, [1], false, 'Dyeing', ''),
// new Process(26, [1], false, 'Printing', ''),

// *** Accessory Factory end  *** //


// *** Fabric Printing Mill start *** //
// new Process(27, [5], false, 'Rotary Printing', ''),
// new Process(28, [5], false, 'Flat Bed Printing', ''),
// new Process(29, [5], false, 'Digital Printing', ''),
// new Process(30, [5], false, 'Sublimation', ''),
// *** Fabric Printing Mill end *** //


//new Process(31, [1], false, 'Planting', ''),// Accessory Factory


// *** Washing Mill start *** //
// new Process(33, [8], false, 'Enzyme Washing', ''),
// new Process(34, [8], false, 'Over-Dyeing', ''),
// new Process(35, [8], false, 'Softning', ''),
// new Process(36, [8], false, 'Rinse Wash', ''),
// new Process(37, [8], false, 'Garment Dyieing/Tinting', ''),
// *** Washing Mill end *** //
// new Process(38, [4], false, 'Other', 'PRE_TREATMENT'),
// new Process(39, [4], false, 'Other', 'DYEING'),
// new Process(40, [4], false, 'Other', 'FINISHING'),


//Use +1 count & Update this counter for your reference on adding new process
//Latest Max count= 40
// new Process(32, [5, 8], false, 'Other', ''),






// Common
// new Process(111, [  6, 7, 9, 10, 11, 12, 13, 15, 14, 16], false, 'Test Process 1', ''),
// new Process(112, [  6, 7, 9, 10, 11, 12, 13, 15, 14, 16], false, 'Test Process 2', ''),
// new Process(113, [  6, 7, 9, 10, 11, 12, 13, 15, 14, 16], false, 'Test Process 3', ''),
// new Process(31, [  6, 7, 9, 10, 11, 12, 13, 15, 14, 16], false, 'Other', ''),
//];

