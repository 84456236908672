import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CubejsApi } from '@cubejs-client/core';
import { environment } from 'environments/environment';
import cubejs from '@cubejs-client/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CubejsService {

    cubejs: CubejsApi;

    constructor(private http: HttpClient) { }

    async getCubeJSData(query: object, factoryId?: number, brandId?: number, onlyScope?: boolean, includeCommodities?: boolean): Promise<any> {
        return this.getAuthorization(factoryId, brandId, onlyScope, includeCommodities).then(() => this.cubejs.load(query))
            .then(resultSet => resultSet)
            .catch(error => Promise.reject(error));
    }


    private getAuthorization(factoryId?: number, brandId?: number, onlyScope?: boolean, includeCommodities?: boolean): Promise<void> {
        // if (factoryId) {
        //     return new Promise<void>((resolve) => {
        //         this.http.get<string>(environment.baseHref + '/getFactoryCubeToken/' + factoryId).subscribe((res) => {
        //             this.cubejs = cubejs(
        //                 async () => res['token'],
        //                 { apiUrl: environment.cubeAPI }
        //             );
        //             resolve();
        //         });
        //     });
        // }
        return new Promise<void>((resolve) => {
            this.http.get<string>(environment.baseHref + '/getFactoryCubeToken/' + factoryId + '/' + brandId + '/' + onlyScope + '/' + includeCommodities).subscribe((res) => {
                this.cubejs = cubejs(
                    async () => res['token'],
                    { apiUrl: environment.cubeAPI }
                );
                resolve();
            },
                (err) => {
                    throwError(new Error('An error ocurred while getting the Cube token!'));
                });
        });
    }
}
