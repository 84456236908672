import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from '../globals-services/globals.service';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { formatDate } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

@Injectable()
export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
          return formatDate(date,'dd MMM yyyy',this.locale);;
      } else {
          return date.toDateString();
      }
  }
}

@Component({
  selector: 'app-supplier-reporting',
  templateUrl: './supplier-reporting.component.html',
  styleUrls: ['./supplier-reporting.component.css'],
  providers: [
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
]
})
export class SupplierReportingComponent implements OnInit {

  isLoading: boolean;
  myId: string = "";
  myName: string = "";
  factoriesByCountries = [];
  standards = [];
//  complianceResults = {};
  factories = [];
  selectedStandard: string = "";
  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedFactories = [];
  selectedBrand: string = "";
  chartData: Object[] = [];


  subscribedToAdvancedAnalytics: boolean;
  panelCount: number;
  advancedAnalyticsMain: SafeResourceUrl;
  advancedAnalyticsDetails: SafeResourceUrl;
  advancedAnalyticsDownload: SafeResourceUrl;

  constructor(
    private http: HttpClient,
    private globalsService: GlobalsService,
    private sanitizer: DomSanitizer
  ) {
    this.isLoading = false;
   }

  ngOnInit() {
      console.log("starting")

    this.http.get<any>(this.globalsService.baseHref + "/getAnalyticsDashboard").subscribe(
        (response) => {
            console.log(response)
            if (response['payment_status']){
                this.isLoading = false
                this.subscribedToAdvancedAnalytics = true
                this.panelCount = response['panel_count']
                this.advancedAnalyticsMain = this.sanitizeHTTP(response['main'])
                this.advancedAnalyticsDetails = this.sanitizeHTTP(response['secondary'])
                this.advancedAnalyticsDownload = this.sanitizeHTTP(response['download'])
            }else{
                this.subscribedToAdvancedAnalytics = false
                this.fetchCountriesSuppliersStandards();
            }

        },(err)=>{
            console.log(err)
            alert('There was an error verifying the subscription status of the account')
        });

    this.myId = this.globalsService.profile["id"];
    this.myName = this.globalsService.profile["name"];

//    console.log(this.globalsService.profile["id"]);
  }

  fetchCountriesSuppliersStandards() {
    this.isLoading = true;
    this.http.get<any>(this.globalsService.baseHref + "/report/standards").subscribe(
        (response) => {
          this.standards = response["standards"];
          this.http.get<string[]>(this.globalsService.baseHref + '/inventories/getCountries').subscribe(
            (response) => {
              for ( let country of response["countries"] ) {
                let factoriesByCountry = { "country": country, "factories": []};
                this.factoriesByCountries.push(factoriesByCountry);
      
                this.http.get<string[]>(this.globalsService.baseHref + "/inventories/getSuppliers/" + country).subscribe(
                  (response) => {
                    let factoriesOfCountry = response;
                    let factoriesByCountry = this.factoriesByCountries.find( element => element["country"] === country );
      
                    for (let factory of factoriesOfCountry) {
                      factoriesByCountry["factories"].push(factory);
                      this.factories.push(factory);
                    }
                    this.isLoading = false;
                  },
                  (err) => {
                    console.log(err);
                    this.isLoading = false;
                  }
                );
              }
            },
            (err) => {
              console.log(err);
              this.isLoading = false;
            }
          );
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
        }
      );


  }



  compareArrayByObjectProperty( a: any, b: any ): number {
    if ( a["name"] < b["name"] ){
      return -1;
    }
    if ( a["name"] > b["name"] ){
      return 1;
    }
    return 0;
  }

  onCheckboxChange(event, factoryName) {
    if (event.target.checked) {
      this.selectedFactories.push(factoryName);
    } else {
      let index = this.selectedFactories.indexOf(factoryName);
      if (index > -1) {
        this.selectedFactories.splice(index, 1);
      }
    }
  }

  onSubmit() {
/*
    console.log(this.selectedStartDate.toISOString());
    console.log(this.selectedEndDate.toISOString());
    console.log("Standard:" + this.selectedStandard);
*/
    let startDateString;
    let endDateString;
    let requestObject;
    let standardsList;

    if (this.selectedStandard === "") {
      alert("Please select a standard!!");
      return;
    } else if( this.selectedFactories.length === 0) {
      alert("Please selecet at least one factory!");
      return;
    } else if( this.selectedStartDate === undefined || this.selectedStartDate === null || this.selectedEndDate === undefined || this.selectedEndDate === null ) {
      alert("Please select valid start and end dates!");
      return;
    } else if( this.selectedStartDate > this.selectedEndDate ) {
      alert("Please select a start date that is smaller then the selected end date!");
      return;
    }

    // correct for timezone before submitting:
    this.selectedStartDate = new Date(this.selectedStartDate.valueOf() - this.selectedStartDate.getTimezoneOffset() * 60000);
    this.selectedEndDate = new Date(this.selectedEndDate.valueOf() - this.selectedEndDate.getTimezoneOffset() * 60000);

    startDateString = this.selectedStartDate.toISOString().slice(0,10);
    endDateString = this.selectedEndDate.toISOString().slice(0,10);
    standardsList = [this.standards.find(standard => standard["name"] === this.selectedStandard)];
    requestObject = {"start": startDateString, "end": endDateString, "factoryIds": this.selectedFactories, "standards": standardsList, "brandId": this.selectedBrand};
//    console.log(requestObject);
    

    this.isLoading = true;

    this.http.post<any>(this.globalsService.baseHref + "/report/factorySummary", requestObject).subscribe(
      (response) => {
        this.chartData = [];
//        console.log(response);
        for (let factoryData of response["result"]) {
          let factoryName = this.factories.find( factory => factory["id"] === factoryData["factoryId"])["name"];
          let chartData = { 
            "complianceName": factoryName, 
            "chartType": 'yes-no-basic',
            "chartData": [
              {'name': 'Yes', 'value': factoryData["compliant"]},
              {'name': 'No', 'value': factoryData["noncompliant"]},
              {'name': 'Commodities', 'value': factoryData["basic"]}
            ]
          };
          this.chartData.push(chartData);
        }

        this.isLoading = false;
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
      }
    ); 
    this.isLoading = false;
  }

  hasChartData() {
    return (this.chartData.length > 0)
  }

  sanitizeHTTP(token:String){
    const METABASE_SITE_URL = this.globalsService.metaBaseURL;
    const URL = METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=false';

    return this.sanitizer.bypassSecurityTrustResourceUrl(URL);


  }

}