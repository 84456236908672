
import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from '../globals-services/globals.service';
import { ComplianceCriteria as CustomComplianceCriteria } from '../globals-interface/complianceCriteria';
import { Formula } from '../globals-interface/formula';
import { ManuallyAddedFormula } from '../globals-interface/manually_added_formula';
import { GatewayAuthenticationService } from 'app/gateway/authentication/gateway-authentication.service';


@Injectable()
export class ComplianceCheckerService implements OnInit {

  connectedEntities = [];

  complianceCriteria: CustomComplianceCriteria[];

  masterColumns = [];

  showCharts = [];

  showPositiveList = [];

  compliantChemicals = [];

  constructor(
    private globalsService: GlobalsService,
    private http: HttpClient
  ) { }

  ngOnInit() {
  }

  setConnectedEntities(entities: any, user: number) {

    this.connectedEntities = [];

    for (const entity of entities) {
      if (entity.relation === 'Connected') {
        this.connectedEntities.push(entity.id);
      }
    }

    this.connectedEntities.push(user);

    this.complianceCriteria = [];  //custom compliance criteria

    this.getComplianceRules();
  }


  getComplianceRules() {

    for (const entity of this.connectedEntities) {
      this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/getRules/' + entity).subscribe(
        (response) => {
          let complianceCriteria = response;
          if (complianceCriteria.length > 0) {
            for (const compliance of complianceCriteria) {
              if (compliance.enabled === true) {
                this.complianceCriteria.push(compliance);
              }
            }
          }
        },
        (err) => {
          alert("Error! Could not fetch compliance criteria!");
        });
    }
  }


  getCriterionNames() {
    this.http.get<any>(this.globalsService.baseHref + '/complianceCriteria/getMasterColumn').subscribe(
      (columns) => {
        this.masterColumns = columns.sort();
      },
      (err) => {
        console.log(err);
        alert("Error: Could not fetch list of columns from the Master table");
      }
    );
  }

  getComplianceNamesList() {

    let customComplianceCriteriaNames = [];

    for (const customComplianceCriteria of this.complianceCriteria) {

      if (!customComplianceCriteriaNames.includes(customComplianceCriteria.complianceName)) {

        customComplianceCriteriaNames.push(customComplianceCriteria.complianceName)

        customComplianceCriteriaNames.sort(function (a, b) {

          let x = a;
          let y = b;
          return x < y ? -1 : x > y ? 1 : 0;

        });
      }
    }

    return customComplianceCriteriaNames;
  }

  checkCompliance(formulas: Formula[]) { // for Brand and factories. Brand can have 1 Compliance Criteria, Factories can have many

    const formulas_copy = formulas.slice();

    //arrange by ID so that it will get the latest data on the formula

    formulas_copy.sort(function (a, b) {
      const x = a.id;
      const y = b.id;
      return x > y ? -1 : x < y ? 1 : 0;
    });

    const masterList = [];

    for (const customComplianceCriteria of this.complianceCriteria) {

      const result = []; //Array of any Objects
      const uniqueID = [];

      result['complianceName'] = customComplianceCriteria.complianceName;  //custom compliance criteria
      result['showCharts'] = customComplianceCriteria.showCharts;
      result['showPositiveList'] = customComplianceCriteria.showPositiveList;
      result['showOnlyProductionFormula'] = customComplianceCriteria.showOnlyProductionFormula;
      result['positiveChemicals'] = [];
      result['negativeChemicals'] = [];

      for (const formula of formulas_copy) {


        const truthTable = [];

        // tslint:disable-next-line: max-line-length
        //        console.log(formula.masterID)

        if (!uniqueID.includes(formula.masterID) ||
          (!uniqueID.includes(this.removeSpecialChars(formula.name).concat(this.removeSpecialChars(formula.manufacturer)))
            && formula.masterID === '0')) {

          uniqueID.push(formula.masterID);

          if (formula.masterID === '0') {

            uniqueID.push(this.removeSpecialChars(formula.name).concat(this.removeSpecialChars(formula.manufacturer)));
          }

          for (const rule of customComplianceCriteria.listOfRules) {

            truthTable.push(this.ruleChecker(rule, formula));
          }


          if (truthTable.filter(item => item === 'false').length < 1 && truthTable.filter(item => item === 'true').length > 0 && customComplianceCriteria.logic === 'and') {


            result['positiveChemicals'].push(formula);

          } else if (truthTable.filter(item => item === 'true').length > 0 && customComplianceCriteria.logic === 'or') {

            result['positiveChemicals'].push(formula);

          } else {

            result['negativeChemicals'].push(formula);
          }


        }


      }

      //alphabetical order
      result['positiveChemicals'].sort(function (a, b) {

        let x = a.name;
        let y = b.name;
        return x < y ? -1 : x > y ? 1 : 0;

      });

      result['negativeChemicals'].sort(function (a, b) {

        let x = a.name;
        let y = b.name;

        return x < y ? -1 : x > y ? 1 : 0;

      });

      masterList.push(result);

    }

    return masterList;

  }


  //Apply the custom compliance criteria to the manually added formula
  checkManuallyAddedCompliance(formulas: ManuallyAddedFormula[]) {

    const formulas_copy = formulas.slice();

    //arrange by ID so that it will get the latest data on the formula

    formulas_copy.sort(function (a, b) {
      const x = a.id;
      const y = b.id;
      return x > y ? -1 : x < y ? 1 : 0;
    });

    const masterList = [];

    for (const customComplianceCriteria of this.complianceCriteria) {

      const result = [];
      const uniqueID = [];


      result['complianceName'] = customComplianceCriteria.complianceName;
      result['showCharts'] = customComplianceCriteria.showCharts;
      result['showPositiveList'] = customComplianceCriteria.showPositiveList;
      result['showOnlyProductionFormula'] = customComplianceCriteria.showOnlyProductionFormula;
      result['positiveChemicals'] = [];
      result['negativeChemicals'] = [];

      for (const formula of formulas_copy) {

        const truthTable = [];

        if (!uniqueID.includes(formula.msdsIdentifier)) {

          uniqueID.push(formula.msdsIdentifier);

          for (const rule of customComplianceCriteria.listOfRules) {

            truthTable.push(this.ruleCheckerManuallyAdded(rule, formula));
          }

          if (truthTable.filter(item => item === 'false').length < 1 &&
            truthTable.filter(item => item === 'true').length > 0 && customComplianceCriteria.logic === 'and') {
            result['positiveChemicals'].push(formula);

          } else if (truthTable.filter(item => item === 'true').length > 0 && customComplianceCriteria.logic === 'or') {

            result['positiveChemicals'].push(formula);

          } else {

            result['negativeChemicals'].push(formula);
          }
        }
      }
      //alphabetical order
      result['positiveChemicals'].sort(function (a, b) {
        let x = a.name;
        let y = b.name;
        return x < y ? -1 : x > y ? 1 : 0;
      });

      result['negativeChemicals'].sort(function (a, b) {
        let x = a.name;
        let y = b.name;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      masterList.push(result);
    }
    return masterList;
  }


  ruleChecker(rule: any, formula: Formula) {

    let logic = rule["logic"];

    let members = rule["members"];

    let ruleTruthTable = [];

    for (let member of members) {

      // check if rule is empty
      if (member["memberKey"] === null || member["memberKey"] === '' || member["memberValue"] === null || member["memberValue"] === "") {
        alert("Error: Compliance rule undefined")
      }
      //check if member is ZDHC mrsl && factory has active gateway subscription then if chemical
      //has gateway level >1 then it is verified chemical

      let iszdhcMrsl: boolean = member["memberKey"] === 'zdhcMrsl';



      if (iszdhcMrsl && GatewayAuthenticationService.isActiveGatewaySubcription && formula['gatewayLevel'] != null && (formula['gatewayLevel'].includes('v2') || formula['gatewayLevel'].includes('v3'))) {
        ruleTruthTable.push("true");
        console.info(formula['name'] + " :  " + formula['gatewayLevel'] + " " + member["memberKey"])

      } else if (formula[member["memberKey"]]   && !(formula[member["memberKey"]] === null) && (formula[member["memberKey"]].toLowerCase() === member["memberValue"].toLowerCase())) {
        ruleTruthTable.push("true");

      } else {
        ruleTruthTable.push("false");
      }
    }

    if (members.length === 0) {
      // Return true because there are no rules that can be broken
      return true;
    }
    else if (members.length === 1) {
      // Rule has only one member
      return ruleTruthTable[0];
    } else if (members.length > 1 && logic === "or") {
      // If ruleTruthTable contains at least one true value return true
      let returnValue = ruleTruthTable.includes("true") ? "true" : "false";
      return returnValue;
    } else if (members.length > 1 && logic === "and") {
      // If ruleTruthTable contains at elast one false value return false
      let returnValue = ruleTruthTable.includes("false") ? "false" : "true";
      return returnValue;
    }
  }

  ruleCheckerManuallyAdded(rule: any, manAddFormula: ManuallyAddedFormula) {
    let logic = rule["logic"];
    let members = rule["members"];
    let ruleTruthTable = [];

    for (let member of members) {
      // check if rule is empty
      if (member["memberKey"] === null || member["memberKey"] === '' || member["memberValue"] === null || member["memberValue"] === "") {
        alert("Error: Compliance rule undefined")
      }

      if (GatewayAuthenticationService.isActiveGatewaySubcription && manAddFormula['gatewayLevel'] != null
        && (manAddFormula['gatewayLevel'].includes('v2') || manAddFormula['gatewayLevel'].includes('v3'))) {
        ruleTruthTable.push("true");
        console.info("Manually added-" +manAddFormula['name'] + " :  " + manAddFormula['gatewayLevel'] + " " )

      } else  if (member["memberKey"].toLowerCase() === 'category' && member["memberValue"].toLowerCase() === 'basic chemical' && manAddFormula.basicChemicalIdentifier === '1') {
          ruleTruthTable.push("true");
        } else {
          ruleTruthTable.push("false");
        }
    }

    if (logic = "or") {
      // If ruleTruthTable contains at least one true value return true
      return ruleTruthTable.includes("true") ? "true" : "false";
    } else if (logic === "and") {
      // If ruleTruthTable contains at elast one false value return false
      return ruleTruthTable.includes("false") ? "false" : "true";
    }
  }
  removeSpecialChars(string) {
    return string.toLowerCase().replace(/[-,%_;:.\(\)\[\]\{\}\\\/\s\r]/g, '');
  }

}